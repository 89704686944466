<div class="layout">
  <div class="logobox">
    <img
      alt="Logo"
      class="logo animate-pulse on-ready-fade-out"
      [src]="logoUrl"
    />

    <svg
      class="circle"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="36.0001"
        cy="36.0004"
        r="36"
        fill="white"
        fill-opacity="0.31"
      />
    </svg>

    <svg
      class="checkmark"
      width="136"
      height="136"
      viewBox="0 0 136 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68 136C105.555 136 136 105.555 136 68C136 30.4446 105.555 0 68 0C30.4446 0 0 30.4446 0 68C0 105.555 30.4446 136 68 136ZM111.454 49.4418C113.96 46.9366 113.96 42.8748 111.454 40.3695C108.949 37.8643 104.887 37.8643 102.382 40.3695L52.8176 89.934L32.7626 69.8789C30.2573 67.3737 26.1955 67.3737 23.6903 69.8789C21.185 72.3842 21.185 76.446 23.6903 78.9512L48.2815 103.542C50.7867 106.048 54.8485 106.048 57.3538 103.542L111.454 49.4418Z"
        fill="white"
      />
    </svg>
  </div>

  <div class="description">
    <div class="progress-description">
      <p>
        <ng-container i18n="@@payment-progress.pending.description"
          >Wir verarbeiten die Bestellung.</ng-container
        >
        <br />
        <ng-container i18n="@@payment-progress.pending.instruction"
          >Bitte laden Sie die Seite nicht neu.</ng-container
        >
      </p>

      <svg
        class="loading"
        width="auto"
        height="6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="100%" height="6" rx="3" fill="white" fill-opacity="0.25" />
        <rect id="loadingbar" width="5%" height="6" rx="3" fill="white" />
      </svg>
    </div>

    <div class="success">
      <p>
        <ng-container i18n="@@payment-progress.pending.description"
          >Wir verarbeiten die Bestellung.</ng-container
        >
        <br />
        <ng-container i18n="@@payment-progress.pending.instruction"
          >Bitte laden Sie die Seite nicht neu.</ng-container
        >
      </p>
    </div>
  </div>
</div>
